// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_VOiwf{display:grid;grid-template-columns:1fr;grid-gap:8px;color:#fff;background:#1c2735;border-radius:8px;padding:12px}.card_orc01{display:grid;grid-template-columns:40px 1fr 20px;grid-gap:12px;width:100%;align-items:center}.cover_c6bcS{border-radius:4px}.cover__empty_KxIUd{height:40px;width:40px;border-radius:4px;background:#334153}.title_pi23-{color:#fff;font-size:16px;font-weight:500;line-height:20px}.icon_2txHM{width:20px;height:20px;rotate:90deg;scroll-margin:140px;cursor:pointer}.iconOpened_SO\\+Ty{rotate:270deg}.tags_nVbZR{display:none}@media(min-width: 1300px){.tags_nVbZR{display:flex;flex-wrap:wrap;border-top:1px solid #334153;padding-top:8px}}.tagsOpened_77a43{display:flex;flex-wrap:wrap;border-top:1px solid #334153;padding-top:8px}.tag_sYkiQ{display:flex;align-items:center;justify-content:center;padding:8px;background:#2b3848;color:#fff;border-radius:8px;font-size:12px;font-weight:400;line-height:16px;margin-right:8px;margin-bottom:8px;cursor:pointer}.tag_sYkiQ:last-child{margin-right:0}.tagWithIcon_w2rzB{background:#334153}.tagWithIcon_w2rzB:hover{background:#455566}.tagNoSeo_GH\\+qM{background:rgba(236,43,45,.2) !important}.tag__icon_EUkdW{width:16px;height:16px;object-fit:contain}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_VOiwf",
	"card": "card_orc01",
	"cover": "cover_c6bcS",
	"cover__empty": "cover__empty_KxIUd",
	"title": "title_pi23-",
	"icon": "icon_2txHM",
	"iconOpened": "iconOpened_SO+Ty",
	"tags": "tags_nVbZR",
	"tagsOpened": "tagsOpened_77a43",
	"tag": "tag_sYkiQ",
	"tagWithIcon": "tagWithIcon_w2rzB",
	"tagNoSeo": "tagNoSeo_GH+qM",
	"tag__icon": "tag__icon_EUkdW"
};
module.exports = ___CSS_LOADER_EXPORT___;
